import { render, staticRenderFns } from "./PDFViewer.vue?vue&type=template&id=f53da12e&scoped=true&"
import script from "./PDFViewer.vue?vue&type=script&lang=js&"
export * from "./PDFViewer.vue?vue&type=script&lang=js&"
import style0 from "pdfvuer/dist/pdfvuer.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./PDFViewer.vue?vue&type=style&index=1&id=f53da12e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f53da12e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
