<template>
    <div id="pageViewLayout" class="page">
        <v-container class="root-container px-4 px-md-10 py-0 container-title" fluid>
            <slot name="header" />
        </v-container>
        <v-container class="root-container px-4 px-md-10 pt-4 pb-6 container-body" fluid>
            <slot name="content" />
        </v-container>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
