<template>
    <div>
        <div class="toolbar d-flex align-center justify-space-between">
            <div>
                <a class="item" @click="scale += scale < 2 ? 0.1 : 0">
                    <v-icon>mdi-magnify-plus-outline</v-icon>
                </a>
                <a class="item" @click="scale -= scale > 0.2 ? 0.1 : 0">
                    <v-icon>mdi-magnify-minus-outline</v-icon>
                </a>
                <a class="item" @click="rotationAngle += 90">
                    <v-icon>mdi-rotate-right</v-icon>
                </a>
            </div>
            <div>{{ fileName }}</div>
            <div>
                <a class="item" @click="viewPdf(src)">
                    <v-icon>mdi-download</v-icon>
                </a>
            </div>
        </div>
        <div ref="contentWrapper" class="content" :style="pdfWrapperHeightStyle">
            <pdf
                v-for="pageNumber in numPages"
                :id="pageNumber"
                :key="getPageKey(pageNumber)"
                class="page"
                text
                annotation
                :src="pdfdata"
                :page="pageNumber"
                style="margin: 0px auto; width: 95%; border-bottom: 10px solid black"
                :scale.sync="scale"
                :rotate="rotationAngle"
            >
                <template slot="loading"> loading... </template>
            </pdf>
        </div>
    </div>
</template>

<script>
import pdfvuer from 'pdfvuer';
import { getRemainingWindowHeight } from '@/helpers/styleHelpers';

export default {
    components: {
        pdf: pdfvuer
    },

    props: {
        src: {
            required: true,
            type: String
        },
        fileName: {
            required: true,
            type: String
        }
    },

    data() {
        return {
            rotationAngle: 0,
            scale: 'page-width',
            pdfWrapperHeight: 0,
            numPages: 0,
            page: 1
        };
    },
    computed: {
        pdfWrapperHeightStyle: function () {
            return `--pdf-wrapper-height: ${this.pdfWrapperHeight}px`;
        }
    },

    watch: {
        src() {
            this.getPdf();
        }
    },

    mounted() {
        this.getPdf();
        const contentWrapperRef = this.$refs.contentWrapper;
        this.pdfWrapperHeight = getRemainingWindowHeight(contentWrapperRef);
    },

    methods: {
        getPageKey(pageNumber) {
            // Setting a dynamic key, because when the PDF view is reload with another pdf, the page should be rerendered
            return `${this.fileName}-${pageNumber}`;
        },
        async getPdf() {
            this.pdfdata = this.getPDFData();
            this.numPages = await this.pdfdata.then((pdf) => pdf.numPages);
            this.resetPDFScale();
        },
        getPDFData() {
            return pdfvuer.createLoadingTask(this.reformatURLForGoogleStorageCORS(this.src));
        },
        resetPDFScale() {
            this.scale = 'page-width';
        },
        viewPdf(url) {
            window.open(url, '_blank');
        },
        reformatURLForGoogleStorageCORS(url) {
            const pieces = url.split('/');
            const storageName = pieces[2];
            const bucketName = pieces[3];

            const newBucketName = `${bucketName}.${storageName}`;

            return url.replace(`${storageName}/${bucketName}`, newBucketName);
        }
    }
};
</script>
<style src="pdfvuer/dist/pdfvuer.css"></style>

<style lang="scss" scoped>
@import '../sass/variables.scss';
$backgroundColor: #f9f9f9;
$page-header-height: 81px;

.toolbar {
    max-height: 48px;
    height: 48px;
    background-color: white;
    padding: 0px 20px;
    border: 1px solid #e3ebf6;
}

.toolbar a {
    margin-right: 15px;
}

.content {
    display: flex;
    flex-direction: column;
    max-height: #{var(--pdf-wrapper-height)};
    height: #{var(--pdf-wrapper-height)};
    padding-top: 30px;
    overflow: scroll;
    border: 1px solid #e3ebf6;
    border-top: 0px;
    background-color: $backgroundColor;

    .page {
        border-bottom: 10px solid $backgroundColor !important;
    }
}

canvas {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 40%);
}
</style>
